import axios from "@/api/axios"; // 引用axios

export const getData = (params) => {
    return axios({
        url: "/api/send/gdj/omdList",
        method: "post",
        params: params,
    });
};

export const downloadTar = (params) => {
    return axios({
        // headers:{
        //     responseType: "blob",
        // },
        url: "/api/send/gdj/DownloadTar",
        method: "get",
        params: params,
    });
};


export const sendOMDRequest = (params) => {
    return axios({
        url: "/api/send/gdj/sendOMDRequest",
        method: "post",
        params: params,
    });
};export const getLower = (params={}) => {
    return axios({
        url: "/api/platform/system/lower",
        method: "get",
        params: params,
    });
};