basic-table

<template>
  <!--&lt;!&ndash;  <div class="tableBox" :style="{height:tableBoxHeight}">&ndash;&gt;         :height="height"-->

  <div class="tableBox">
    <el-table
        ref="table"
        @selection-change="handleSelection"
        cell-class-name="tdClass"
        header-cell-class-name="HtdClass"
        header-row-class-name="tableHeader"
        :data="tableData"
        style="width: 100%"
        align="center"
        empty-text="暂无内容"
        row-key="id"
        border
    >
      <el-table-column type="selection" width="55" />
      <el-table-column
          type="index"
          width="100"
          align="center"
          v-if="indexshow"
      />
      <el-table-column
          v-for="(item, index) in attrList"
          :key="index"
          :prop="item.prop"
          :label="item.label"
          :width="item.width"
          align="center"
          show-overflow-tooltip
      >
        <template #default="scope">
          <dataContent :data="scope.row" :render="item.render" />
        </template>
      </el-table-column>
    </el-table>

    <div class="page" v-if="isPage">
      <el-pagination
          v-model:currentPage="current"
          :page-sizes="[10, 20, 50, 100]"
          :page-size="limit"
          layout=" prev, pager, next, jumper,total, sizes,"
          :total="total"
          @size-change="handleSize"
          @current-change="handleCurrent"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
export default {
  name: "basic-table",
  props: {
    tableData: {
      type: Array,
      default: function () {
        return [];
      },
    },
    attrList: {
      type: Array,
      default: function () {
        return [];
      },
    },
    isPage:{
      type: Boolean,
      default: true
    },
    page: {
      type: Number,
      default: 1,
    },
    limit: {
      type: Number,
      default: 10,
    },
    total: {
      type: Number,
      default: 0,
    },
    indexshow: {
      type: Boolean,
      default: true,
    },
    height: {
      type: Number,
      default: 525,
    },
    tableBoxHeight: {
      type: String,
      default: "615PX",
    },
  },
  watch: {
    page(newvalue, oldVal) {
      this.current = newvalue;
    },
  },
  components: {
    dataContent: {
      functional: true,
      props: {
        data: {
          type: Object,
          default: () => {},
        },
        render: {
          type: Function,
        },
      },
      render(ctx) {
        return ctx.render(ctx.data);
      },
    },
  },
  data() {
    return {
      current: 1,
      // page: {
      //   current: 1,
      //   pageSize: 10,
      //   total: 0
      // }
    };
  },
  methods: {
    handleSelection(value) {
      this.$emit("selectChange", value);
    },
    handleSize(pageSize) {
      this.$emit("sizeChange", pageSize);
    },
    handleCurrent(currentPage) {
      this.$emit("currentChange", currentPage);
    },
    clearSelect() {
      this.$refs.table.clearSelection();
    },
  },
};
</script>

<style scoped>
/*/deep/ .el-input .el-input__inner {*/
/*  width: 60px !important;*/
/*  height: 30px !important;*/
/*  background: #061C1A !important;*/
/*  border: 1px solid #105044 !important;*/
/*  color: #FF48A28D;*/
/*}*/
/*.page /deep/ .btn-prev {*/
/*  background: none;*/
/*}*/
.page /deep/ .el-pager li.active {
  width: 30px;
  height: 30px;
  background: #0d3734;
  border: 1px solid #146354;
}

.page /deep/ .el-pager li.number {
  color: #48a28d;
}

.page /deep/ .el-pagination button:disabled {
  background: none;
}

/deep/ .el-table .cell {
  font-size: 18px !important;
}

.page {
  /*position: absolute;*/
  /*bottom: 20px;*/
  /*right: 0;*/
  width: 100%;
  text-align: right;
  padding: 20px 0;
}

.page /deep/ .el-pagination span:not([class*="suffix"]) {
  vertical-align: baseline;
}

.el-pagination /deep/ .el-input__inner {
  width: 110px !important;
  max-height: 30px !important;
  min-height: 30px !important;
  font-size: 16px;
  font-family: Source Han Sans SC !important;
  font-weight: 300 !important;
  color: #48a28d !important;
}
.el-pagination /deep/ span {
  color: #48a28d !important;
}

.page /deep/ .el-pagination .el-select .el-input {
  width: auto;
  margin: 0;
}

/deep/ .el-pagination {
  --el-pagination-bg-color: none;
}
</style>
