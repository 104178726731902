import axios from "@/api/axios"; // 引用axios

export const getMenuMana = (params) => {
  return axios({
    url: "/api/webMenu/getMenuData",
    method: "post",
    params
  });
};
//新增
export const addMenu = (params) => {
  return axios({
    url: "/api/webMenu/addData",
    method: "post",
    params: params,
  });
};
//编辑
export const editMenu = (params) => {
  return axios({
    url: "/api/webMenu/editData",
    method: "post",
    params: params,
  });
};
//删除
export const delMenu = (params) => {
  return axios({
    url: "/api/webMenu/del",
    method: "post",
    params: params,
  });
};
//获取所有的权限
export const getMenuPerm = () => {
  return axios({
    url: "/api/webMenu/getMenuList",
    method: "post",
    params: {},
  });
};
