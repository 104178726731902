export default [
    {
        path: "motorroom_centre",
        name: "motorroom_centre",
        component: () =>
            import(
                /* webpackChunkName: "centre" */ "../views/motorroom/centre.vue"
                ),
    },
    {
        path: "motorroom_sensorConfig",
        name: "motorroom_sensorConfig",
        component: () =>
            import(
                /* webpackChunkName: "sensorConfig" */ "../views/motorroom/sensorConfig.vue"
                ),
    },
    {
        path: "motorroom_history",
        name: "motorroom_history",
        component: () =>
            import(
                /* webpackChunkName: "history" */ "../views/motorroom/history.vue"
                ),
    },
    {
        path: "motorroom_marn",
        name: "motorroom_marn",
        component: () =>
            import(
                /* webpackChunkName: "marn" */ "../views/motorroom/marn.vue"
                ),
    },
    {
        path: "motorroom_sensor",
        name: "motorroom_sensor",
        component: () =>
            import(
                /* webpackChunkName: "sensor" */ "../views/motorroom/sensor.vue"
                ),
    }
];