import axios from "@/api/axios"; // 引用axios
//获取用户列表
export const getUser = (page, limit, params) => {
  const param = {
    ...params,
    page,
    limit
  };
  return axios({
    url: "/api/user/listData",
    method: "post",
    params: param
  });
};
//新增用户
export const addUser = (params) => {
  return axios({
    url: "/api/user/addData",
    method: "post",
    params: params
  });
};
//编辑用户
export const editUser = (params) => {
  return axios({
    url: "/api/user/editData",
    method: "post",
    params: params
  });
};
//获取详情
export const getDetail = (id) => {
  return axios({
    url: "/api/user/getDetails",
    method: "post",
    params: { id }
  });
}; //删除用户
export const delUser = (id) => {
  return axios({
    url: "/api/user/del",
    method: "post",
    params: { id }
  });
};
//更换头像
export const updateImg = (params) => {
  return axios({
    url: "/api/user/upimg",
    method: "post",
    params: params
  });
};
//根据用户 id 和账号 userNo 初始化密码
export const initPwd = (params) => {
  return axios({
    url: "/api/user/initPassword",
    method: "post",
    params: params
  });
};
//绑定摄像头
export const bindCamera = (params) => {
  console.log(params);
  return axios({
    url: "/api/user/saveCamera",
    method: "post",
    params: params
  });
};
//获取用户绑定摄像头
export const getCamera = (userId) => {
  return axios({
    url: "/api/user/setCamera",
    method: "post",
    params: {
      userId: userId
    }
  });
};
//用户绑定摄像头时获取终端
export const getTerminalList = (userId) => {
  return axios({
    url: "/api/region/regionTreeAdapter",
    method: "post",
    params: {
      userId: userId
    }
  });
};
//解绑
export const unBind = (id) => {
  return axios({
    url: "/api/user/untying",
    method: "post",
    params: {
      id: id
    }
  });
};
//账号启用或禁用
export const orEnabled = (id,state) => {
  return axios({
    url: "/api/user/disOrEnb",
    method: "post",
    params: {
      id: id,
      state: state,
    }
  });
};
//退出登陆
export const logout = (id,state) => {
  return axios({
    url: "/api/logout",
    method: "post",
    params: {}
  });
};
// 登陆页logo
// 首页左上角logo
export const getHomeTitleParam = () => {
  return axios({
    url: "/api/sysParam/getHomeTitleParam",
    method: "post",
  });
};

export const getData = () => {
  return axios({
    url: "/api/version/getData",
    method: "post",
  });
};
