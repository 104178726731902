<template>
<!--  <keep-alive include='Index,Home'>-->
    <router-view />
<!--  </keep-alive>-->

    <el-dialog append-to-body v-model="isresetPwd" title="修改密码" width="30%" :before-close="handleClose">
      <el-form label-position="right" label-width="auto" :model="formLabelAlign">
        <el-form-item label="旧密码：" prop="oldPsw">
          <el-input type="password" v-model="formLabelAlign.oldPsw" placeholder="请输入旧密码" />
        </el-form-item>
        <el-form-item label="新密码：" prop="newPsw1">
          <el-input type="password" v-model="formLabelAlign.newPsw1" placeholder="请输入新密码" />
        </el-form-item>
        <el-form-item label="确认新密码：" prop="newPsw">
          <el-input type="password" v-model="formLabelAlign.newPsw" placeholder="请确认新密码" />
        </el-form-item>
      </el-form>

      <template #footer>
          <span class="dialog-footer">
            <el-button type="primary" @click="onsetPwd">确定</el-button>
          </span>
      </template>
    </el-dialog>
</template>
<script>
import {getHomeTitleParam, getData} from "./api/system/user";
import { ElNotification } from "element-plus";
import { mapMutations, mapGetters } from 'vuex';
import {editPwd, getuserInfo} from "./api/userinfo";
import { clearCookie } from "@/util/auth";
export default {
  name: "index",
  data() {
    return {
      formLabelAlign:{},
    };
  },

  computed:{
    ...mapGetters(["isresetPwd"])
  },

  methods:{
    ...mapMutations(['GET_TITLE', "MUTATIONA_PWD_DIALOG"]),
    handleClose(done){
      this.formLabelAlign = {};
      this.MUTATIONA_PWD_DIALOG(false);
      done();
    },
    onsetPwd(){
      let { oldPsw, newPsw } = this.formLabelAlign;
      getuserInfo().then(res=>{
        let { id } = res.data.data;
        editPwd({
          id,
          oldPsw,
          newPsw
        }).then(res2=>{
          if (res2.data.success) {
            this.$message.success("操作成功");
            this.formLabelAlign = {};
            this.MUTATIONA_PWD_DIALOG(false);
            clearCookie();
            this.$router.replace("/login");
          }
        })
      })
    },
    timer(){
      setTimeout(async ()=>{
        if(this.load) return;
        try {
          let res = await getData();
          ElNotification({
            title: "消息提示",
            position: "bottom-right",
            dangerouslyUseHTMLString: true,
            message: `<div>${res.data.data.msg}</div>`
          });
        }catch (error){
          // throw new Error(error);
        }
        this.timer()
      }, 1000*60*10)
    }
  },

  mounted() {
    this.timer()
    getHomeTitleParam().then(res=>{
      this.GET_TITLE(res.data.data)
    })
  }
};
</script>
<style lang="scss">
.amap-icon img{
  position:relative !important;
}
.amap-logo, .amap-copyright { display:none !important; }
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  background: #122420;
}
//.el-form-item{
//  margin-bottom: 20px;
//}
.el-form-item__label {
  color: #32B89C;
  font-size: 18px;
}

.el-form-item__content {
  line-height: 40px !important;
  display: inline-block !important;
  font-family: Source Han Sans SC !important;
  font-weight: 300 !important;
  font-size: 18px !important;
  color: #95D7C8 !important;
  //background: #061C1A !important;
  //border: 1px solid #126B51 !important;
  border-radius: 4px;
  //width: 276px !important;
  //height: 46px !important;
}

.el-form-item.is-error .el-input__inner, .el-form-item.is-error .el-input__inner:focus, .el-form-item.is-error .el-textarea__inner, .el-form-item.is-error .el-textarea__inner:focus {
  border-color: #f56c6c !important;
}

.el-textarea, {
  width: 100%;
  height: 100%;
}

.el-input .el-input__inner, .el-input-number {
  display: inline-block !important;
  color: #b5f0e4 !important;
  font-weight: 300 !important;
  width: 100% !important;
  //height: 100% !important;
  min-height: 46px !important;

  background: #061C1A !important;
  border: 1px solid #126B51 !important;
}

.el-textarea__inner {
  display: inline-block !important;
  color: #95D7C8 !important;
  font-weight: 300 !important;
  width: 100% !important;
  height: 100% !important;

  background: #061C1A !important;
  border: 1px solid #126B51 !important;
}

.el-input__inner:focus {
  border-color: #1C9C77 !important;
}

.el-input__inner:hover, .el-textarea__inner:hover {
  background: #0A2B28 !important;
  border-color: #1C9C77 !important;
}

.el-textarea__inner:focus {
  border-color: #1C9C77 !important;
}


#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}

.tdClass, .HtdClass {

  border: 1px solid #10514C !important;
  //text-align: center !important;
}


.tableHeader {
  font-size: 20px;
  font-family: Source Han Sans SC;
  font-weight: 400;
  color: #00FFC6;
  //line-height: 48px;
  background: url("./assets/tableHeader.png") no-repeat left top;
  background-size: 100% 100%;
}

.el-table {
  --el-table-row-hover-bg-color: #0D3A36 !important;
  --el-table-border-color: none !important;
  //--el-table-tr-bg-color: none !important;
  //background-color: #FF061715 !important;
}

.el-table th.el-table__cell {
  background: none !important;
}

.el-table .el-table__cell {
  //height: 60px !important;
  padding: 8px 0 !important;
}

.tableBox {
  //::-webkit-scrollbar {
  //  width: 0;
  //}
  //position: relative;
  //height: 100%;
  padding: 10px;
  background-color: #0A2B28;
  border: 1px solid #178164;
  //margin-bottom: 200px;
  //background: url("./assets/tableBg.png") no-repeat center;
}

.el-table__body {
  background: #061715;
  opacity: 0.7;
  font-size: 16px;
  font-family: Source Han Sans SC;
  //font-weight: 500;
  color: #b5f0e4;
  line-height: 48px;
}

.searchBtn.el-button {
  font-size: 20px;
  color: #6DEDD0;
  //width: 116px;
  height: 50px;
  //border: 1px solid #24AD8E;
  background: url("./assets/smainBtn.png") no-repeat center;
  background-size: 100% 100%;
}

.resetBtn.el-button {
  font-size: 20px;
  color: #6DEDD0;
  width: 116px;
  height: 50px;
  //border: 1px solid #24AD8E;
  background: url("./assets/littleBlack.png") no-repeat center;
}

.emphasizeBtn.el-button {
  font-size: 20px;
  font-family: Source Han Sans SC;
  font-weight: 300;
  color: #E8BD3B;
  width: 116px;
  height: 50px;
  background: url("./assets/keyNormal.png") no-repeat center;
}

.el-form-item__label {
  font-size: 18px !important;
  font-family: Source Han Sans SC !important;
  font-weight: 300 !important;
  color: #32B89C !important;
  line-height: 48px !important;
}

.el-button .el-icon svg {
  width: 19px;
  height: 19px;
}

.divide {
  width: 100%;
}

.btnContainer {
  padding-top: 10px;
}

.btnLeft {
  display: flex;
  width: 100%;
  text-align: left;
}

.searchBox {
  padding: 15px 10px;
  background: #0c3834;
  border: 1px solid #178164;
  margin-bottom: 10px;
}

.el-dialog {
  background: #0A2B28 !important;
  border: 1px solid #178164 !important;
}

.el-drawer {
  background: #0A2B28 !important;
}

.el-dialog__title {
  color: #24AD8E !important;
}

.el-tree {
  font-size: 20px;
  font-family: Source Han Sans SC;
  font-weight: 400;
  color: #59B7A4 !important;
  --el-tree-node-hover-bg-color: none !important;
  --el-tree-text-color: none !important;
  background: none !important;
}

.el-select__popper, .is-light .is-pure {
  background: #061C1A !important;
  border: 1px solid #178164 !important;

}

.el-select, .el-select--small {
  width: 100% !important;
  height: 100% !important;
}

.el-select-dropdown__item.hover, .el-select-dropdown__item:hover {
  background: none !important;
}

.el-select-dropdown__item {
  font-size: 18px !important;
  font-family: Source Han Sans SC !important;
  font-weight: 300 !important;
  color: #95D7C8 !important;
}

.el-select-dropdown__item .hover, .el-select-dropdown__item:hover {
  background-color: #20554A !important;
}

.el-select-dropdown__list .selected {
  background-color: #20554A !important;
}

.is-current > .el-tree-node__content {
  background: #178164 !important;
}


//.el-tree-node:hover > .el-tree-node__content {
//  background-color: #178164 !important;
//}

.el-tree-node__content:hover {
  background-color: #178164 !important;
}

.el-popup-parent--hidden, body {
  padding-right: 0 !important;
}


//.el-radio {
//  color: #20554A !important;
//}

//.el-radio__input.is-checked .el-radio__inner {
//  background: #32B89C !important;
//  border-color: #32B89C !important;
//}

.is-checked .el-radio__label {
  color: #32B89C !important;;
}

.el-radio.el-radio--small {
  height: 100% !important;
}

.el-radio-group, .el-switch {
  padding-left: 20px;
  height: 100% !important;
  width: 100% !important;
  //border: 1px solid #126B51 !important;
}

.el-picker-panel {
  background: none !important;
  --el-datepicker-active-color: #32B89C !important;
}

.el-date-editor.el-input, .el-date-editor.el-input__inner {
  width: 100% !important
}

//.el-date-table td.today .el-date-table-cell__text {
//  color: #32B89C !important;
//}

.el-date-table td.available:hover {
  color: #32B89C !important;
}

.el-date-table th {
  border-bottom: solid 1px #126B51 !important;
}

//.el-radio .el-radio--small{
//  height: none !important;
//}
.el-input-number.el-input-number--small.is-controls-right {
  width: 100%;
}

.el-input-number .el-input__inner {
  text-align: left !important;
}

.el-input-number__decrease, .el-input-number__increase {
  height: 100% !important;
  line-height: 20px !important;
  background: #32B89C !important;
  border-bottom: #32B89C !important;
  border-left: #32B89C !important;
}

.el-input-number .el-icon, .el-icon svg {
  height: 100% !important;
}

.el-input-number__decrease:hover, .el-input-number__increase:hover, .el-icon:hover {
  color: #00ffc0 !important;
}

.el-date-picker {
  --el-datepicker-inner-border-color: #126B51 !important;
}

.el-picker-panel__footer {
  background: none !important;
}

//.el-button--text, .el-time-panel__btn.cancel {
//  color: #32B89C !important;
//}

.el-button--default.el-picker-panel__link-btn, .el-time-panel__btn.confirm {
  background: #32B89C !important;
  border: #32B89C !important;
  color: #606266 !important;
}

.el-button--default.el-picker-panel__link-btn:hover, .el-time-panel__btn.confirm:hover {
  color: #0A2B28 !important;
}

.el-date-picker__editor-wrap > .el-input--small {
  border: #126B51 1px solid;
}

.el-date-picker__header .el-icon {
  color: #00FFC6
}

.el-picker-panel .el-time-panel {
  margin: 0.026042rem 0;
  border: solid 0.005208rem var(--el-datepicker-border-color);
  background-color: #fff;
  box-shadow: var(--el-box-shadow-light);
}

.el-time-panel {
  border: solid 1px #126B51 !important;
  background: #061C1A !important;
}

.el-time-spinner__item.active {
  color: #32B89C !important;
}

.el-time-spinner__item:hover {
  background: #0D3734 !important;
}

.el-time-panel__content::after, .el-time-panel__content::before {

  border-top: 1px solid #126B51 !important;
  border-bottom: 1px solid #126B51 !important;
}

.ant-select {
  width: 100%;
  height: 100%;
}

.ant-select-selector {
  width: 100%;
  height: 100%;
  background: none !important;
  border: 1px solid #126B51 !important;
}

.ant-select-focused {
  border-color: #1C9C77 !important;
}

.ant-select-selection-placeholder {
  text-align: left;
  //color: #20554A !important;
  //font-size: 18px !important;
  font-family: Source Han Sans SC !important;
  font-weight: 300 !important;
  line-height: 48px !important;
}

.ant-select-dropdown {
  z-index: 2222 !important;
  background: #061C1A !important;
}

.ant-select-tree {
  background: #061C1A !important;
  color: #95D7C8 !important;
}

.ant-select-tree-node-content-wrapper:hover, {
  background-color: #20554A !important;
}

.ant-select-tree-node-selected.ant-select-tree-node-content-wrapper {
  background-color: #20554A !important;
}

.ant-select-multiple .ant-select-selection-item {
  background-color: #20554A !important;
  border: none;
}

.ant-select-clear {
  border-radius: 5px;
}

.el-switch__label {
  color: #20554A !important;
}

.el-switch__label.is-active {
  color: #32B89C !important;
}

.ant-select-selection-item-content {
  color: #95D7C8 !important;
}

//.el-checkbox__input.is-indeterminate .el-checkbox__inner {
//  background-color: #32B89C !important;
//  border-color: #32B89C !important;
//}
.el-button {
  border: none !important;
}

.el-button.el-button--text:hover {
  color: #00FFC6 !important;
}

.el-button.el-button--text:focus {
  color: #00FFC6 !important;
}

:root {
  --el-color-primary-light-2: black !important;
  --el-color-primary-light-7: #32B89C !important;
  --el-color-primary-light-9: #32B89C !important;
  --el-text-color-primary: #24AD8E !important;
  --el-text-color-regular: #80aaa1 !important;
  //--el-color-primary: #32B89C !important;
  --el-color-primary: #24AD8E !important;
  --el-text-color-placeholder: #80aaa1 !important;
  //--el-color-white: #ffffff !important;
  --el-color-white: #061C1A !important;
}

.el-select .el-select__tags .el-tag {
  background-color: #148F75 !important;
  color: #95D7C8 !important;
}

.el-message-box {
  border-color: #24AD8E !important;

}

.el-message-box .el-button {
  color: #1C9C77 !important;
  --el-button-hover-bg-color: #20554A !important;
  --el-button-hover-text-color: #24AD8E !important
}

.el-tabs__item.is-left.is-focus {
  color: #20554A !important;
}

.el-tabs__item.is-left.is-focus.is-active {
  color: #24AD8E !important;
}

.el-table__expand-icon {
  color: #00FFC6 !important;
}

.el-icon {
  color: #b5f0e4 !important;
}

.el-date-editor.el-range-editor {
  border-color: #126B51;


  input {
    background-color: #061C1A;
    color: #b5f0e4;

  }

}

.el-checkbox, .el-radio {
  color: #148F75 !important;
}

//.el-date-range-picker .available.in-range{
//  background-color: ;
//}
.el-message-box__message p {
  color: #00ffc6;

}

.el-drawer__header {
  color: #00ffc0 !important;
  font-size: 16px;
}

.el-checkbox__inner {
  z-index: 0 !important;
}
</style>
