export default [
    {
        path: "devops_info",
        name: "devops_info",
        component: () =>
            import(/* webpackChunkName: "info" */ "../views/devops/info.vue")
    },
    {
        path: "devops_strategy",
        name: "devops_strategy",
        component: () =>
            import(/* webpackChunkName: "strategy" */ "../views/devops/strategy.vue")
    },
    {
        path: "devops_receive",
        name: "devops_receive",
        component: () =>
            import(/* webpackChunkName: "receive" */ "../views/devops/receive.vue")
    },
    {
        path: "devops_send",
        name: "devops_send",
        component: () =>
            import(/* webpackChunkName: "send" */ "../views/devops/send.vue")
    }
];