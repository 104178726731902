<template>
  <div class="top">
    <Header></Header>
  </div>
  <div class="instance">
    <div class="content_left">
      <Sidebar></Sidebar>
    </div>
    <div class="content_right">
      <div class="markDiv" style="height: 100%" id="avue-view">
<!--        <div class="backBtn">-->
<!--          <div style="display: initial;" @click="handlegoback">-->
<!--            <img src="../assets/toBack.png"  />-->
<!--            返回首页看板-->
<!--          </div>-->
<!--        </div>-->
        <div class="centerContent">
          <div class="bigBox">
            <div class="innerBox">
              <router-view v-slot="{ Component }">
                <!--            <keep-alive>-->
                <component :is="Component" />
                <!--            </keep-alive>-->
              </router-view>
            </div>
          </div>
        </div>
<!--        <div class="bottomInfo">-->
<!--          四川璧虹电子信息技术有限公司 All Rights Reserved-->
<!--          邮件：service@scbhcatv.cn 电话：0086-838-5106000-->
<!--          地址：四川省德阳市广汉市中山大道南三段36号-->
<!--          <span>各服务器正常</span>-->
<!--        </div>-->
      </div>
    </div>
  </div>
  <!--  <div class="rightBox" v-if="detailShow">-->
  <!--    <div class="detailHeader">-->
  <!--      <span>消息提示</span>-->
  <!--      <el-icon :size="30" color="#0C7959" @click="detailShow=false">-->
  <!--        <close />-->
  <!--      </el-icon>-->
  <!--    </div>-->
  <!--    <div class="detailContent">-->
  <!--      <div class="detailList">-->
  <!--        <span class="detailName">指令来源：</span>-->
  <!--        <div>{{ form.sourceName ? form.sourceName : "无" }}</div>-->
  <!--      </div>-->
  <!--      <div class="detailList">-->
  <!--        <span class="detailName">接收时间：</span>-->
  <!--        <div>{{ form.receiveTime }}</div>-->
  <!--      </div>-->
  <!--      <div class="detailList">-->
  <!--        <span class="detailName">数据包号：</span>-->
  <!--        <div>{{ form.ebdId }}</div>-->
  <!--      </div>-->
  <!--      <div class="detailList">-->
  <!--        <span class="detailName">数据包类型：</span>-->
  <!--        <div>{{ form.msgType }}</div>-->
  <!--      </div>-->
  <!--      <div>-->
  <!--        <span class="detailBtn">查看详情</span>-->
  <!--      </div>-->
  <!--    </div>-->
  <!--  </div>-->
</template>

<script>
import Header from "@/components/Header";
import Sidebar from "@/components/Sidebar";
import { ElNotification } from "element-plus";
import { ref, toRefs, onMounted, reactive, onBeforeUnmount } from "vue";
import { downloadTar } from "@/api/devops/control";
import { useRouter } from "vue-router";
import { getuserInfo } from "@/api/userinfo";
export default {
  name: "content",
  components: { Header, Sidebar },
  setup() {
    const option = reactive({
      detailShow: true,
      form: {},
      socket: null,
      data: [],
      asd:"equipment_manage"
    });
    const router = useRouter();
    function websocketonopen() {
      // console.log(option.socket);//连接建立之后执行send方法发送数据
      console.log("连接建立");
    }

    function websocketonerror() {//连接建立失败重连
      initWebSocket();
      console.log('err')
    }

    function websocketonmessage(e) { //数据接收
      if (e.data == "WebSocket连接成功") return;
      else {
        let form = JSON.parse(e.data);
        if(form.messageType == 1){
          ElNotification({
            title: "消息提示",
            position: "bottom-right",
            dangerouslyUseHTMLString: true,
            message: `<div>
               <div style=" text-align: left;display: flex;margin-bottom: 8px;">
                 <span style=" width: 100px;">指令来源：</span>
                 <span style=" word-wrap: break-word;text-align: left;width: 190px;">${form.sourceName ? form.sourceName : "无"}  </span>
               </div>
           <div style=" text-align: left;display: flex;margin-bottom: 8px;">
            <span style=" width: 100px;">接收时间：</span>
              <span style=" word-wrap: break-word;text-align: left;width: 190px;">${form.receiveTime}</span>
           </div>
            <div style=" text-align: left;display: flex;margin-bottom: 8px;">
             <span style=" width: 100px;">数据包号：</span>
             <span style=" word-wrap: break-word;text-align: left;width: 190px">${form.ebdId}</span>
            </div>
              <div style=" text-align: left;display: flex;margin-bottom: 8px;">
             <span style=" width: 100px;">数据包类型：</span>
              <span style=" word-wrap: break-word;text-align: left;width: 190px;">${form.msgType}</span>
              </div>
               <div style="text-align: center"">
                 <span style="display: inline-block;width: 115px;height: 35px;line-height: 35px;background: #00715D;color: #D4F3EC;text-align: center;cursor: pointer;"
                  onclick="handledownload('${form.ebdId}')">查看详情</span>
           </div>
             </div>`
          });
        }
      }
    }

    function websocketsend(Data) {//数据发送
      option.websock.send(Data);
    }

    function websocketclose(e) {
      // console.log(option.socket);//关闭
      console.log("断开连接");
    }

    function handledownload(ebdId) {
      downloadTar({
        ebdId: ebdId, type: 2
      }).then(res => {
        if (res.data) {
          // 文件下载
          const blob = new Blob([res.data], {
            type: "application/x-tar"
          });
          let link = document.createElement("a");
          link.href = URL.createObjectURL(blob);
          link.setAttribute("download", ebdId);
          link.click();
          link = null;
        }
      });
    }

    function initWebSocket() { //初始化weosocket
      getuserInfo().then(res=>{
        // const wsuri = `ws://${window.location.hostname}:9889/websocket/${res.data.data.userNo}`;
        const wsuri = `${window.g.socketUrl}/websocket/${res.data.data.userNo}`;
        option.socket = new WebSocket(wsuri);
        option.socket.onmessage = websocketonmessage;
        option.socket.onopen = websocketonopen;
        option.socket.onerror = websocketonerror;
        option.socket.onclose = websocketclose;
      })

    }
    function handlegoback(){
      router.push("/");
    }

    onMounted(() => {
      initWebSocket();
      window.handledownload = handledownload;
    });
    onBeforeUnmount(() => {
      // todo 上线需要删除注释
      // option.socket.close();
      // option.socket = null;
    });
    return {
      router,
      ...toRefs(option),
      websocketonopen,
      initWebSocket,
      websocketonerror,
      websocketonmessage,
      websocketsend,
      websocketclose,
      handledownload,
      handlegoback
    };
  }

};
</script>

<style scoped lang="scss">
.fill {
  width: 100%;
  height: 100%;
  position: relative;
}

.top {
  width: 100%;
  height: 80px;
}

.instance .content_left,
.content_right {
  transition: all 0.3s;
}

.instance {
  box-sizing: border-box;
  /*padding-top: 80px;*/
  padding: 10px;
  width: 100%;
  height: 1000px;
  display: flex;
  justify-content: space-between;
}

.content_left {
  width: 200px;
  height: 980px;
  background: #0c3834;
  border: 1px solid #178164;
}

.content_right {
  width: 1690px;
  height: 100%;
}

.backBtn {
  cursor: pointer;
  font-size: 20px;
  font-family: Source Han Sans SC;
  font-weight: 300;
  color: #32b89c;
  line-height: 48px;
  padding-left: 6px;
  text-align: left;
}

.markDiv {
  /*position: relative;*/
  //display: flex;
  //flex-direction: column;
  //justify-content: space-evenly;
}

.centerContent {
  ::-webkit-scrollbar {
    width: 0;
  }
  width: 1690px;
}

.bottomInfo {
  padding: 0 5px;
  left: 220px;
  bottom: 0;
  position: absolute;
  line-height: 50px;
  text-align: left;
  width: 1690px;
  height: 50px;
  background: #033430;
  font-size: 16px;
  font-family: Source Han Sans SC;
  font-weight: 300;
  color: #448D7C;
}

.bigBox {
  height: 875px;
  //margin-bottom: 300px;
  overflow-y: scroll;
}

.innerBox {
  margin-bottom: 200px;
}

.rightBox {
  width: 400px;
  height: 260px;
  background: rgba(9, 24, 23, 0.9);
  border: 1px solid #0C7959;
  position: absolute;
  bottom: 0;
  right: 0;
}

.detailHeader {
  padding: 5px 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 50px;
  background: rgba(10, 43, 40, 0.9);
  border-bottom: 1px solid #0C7959;
  font-size: 20px;
  font-weight: 300;
  color: #00FFD5;
  line-height: 36px;
}

.detailList {
  text-align: left;
  display: flex;
  margin-bottom: 8px;
}

.detailContent {

  font-size: 16px;
  font-weight: 300;
  color: #11FFBF;
  padding: 10px 10px;
}

.detailName {
  width: 100px;
  color: #50C9B1;
}

.detailList > div {
  word-wrap: break-word;
  text-align: left;
  width: 280px;
}

.detailBtn {
  display: inline-block;
  width: 115px;
  height: 35px;
  line-height: 35px;
  background: #00715D;
  color: #D4F3EC;
}
</style>
