export default [
  {
    path: "orchestration_mana",
    name: "orchestration_mana",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/orchestrate/mana.vue")
  },{
    path: "orchestration_setReturn",
    name: "orchestration_setReturn",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/orchestrate/setReturn.vue")
  },{
    path: "orchestration_whiteList",
    name: "orchestration_whiteList",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/orchestrate/whiteList.vue")
  },{
    path: "orchestration_InputChannelQuery",
    name: "orchestration_InputChannelQuery",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/orchestrate/InputChannelQuery.vue")
  },{
    path: "orchestration_outChannelQuery",
    name: "orchestration_outChannelQuery",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/orchestrate/outChannelQuery.vue")
  },{
    path: "orchestration_faultDetailsQuery",
    name: "orchestration_faultDetailsQuery",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/orchestrate/faultDetailsQuery.vue")
  },{
    path: "orchestration_broadcastQuery",
    name: "orchestration_broadcastQuery",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/orchestrate/broadcastQuery.vue")
  },{
    path: "orchestration_generalPage",
    name: "orchestration_generalPage",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/orchestrate/generalPage.vue")
  },
]