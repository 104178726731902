export default [
  {
    path: "schedule_pendSchedule",
    name: "schedule_pendSchedule",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/schedule/pendSchedule.vue")
  }, {
    path: "schedule_hasScheduled",
    name: "schedule_hasScheduled",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/schedule/hasScheduled.vue")
  },{
    path: "schedule_scheduleCancel",
    name: "schedule_scheduleCancel",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/schedule/scheduleCancel.vue")
  },{
    path: "schedule_emergencyRelease",
    name: "schedule_emergencyRelease",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/schedule/emergencyRelease.vue")
  },{
    path: "schedule_preplanManage",
    name: "schedule_preplanManage",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/schedule/preplanManage.vue")
  },{
    path: "basic_regularRadio",
    name: "basic_regularRadio",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/basic/regularRadio.vue")
  },{
    path: "system_blog",
    name: "system_blog",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/system/blog.vue")
  },
];