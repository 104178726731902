export default [
    {
        path: "videomeeting_addmeeting",
        name: "videomeeting_addmeeting",
        component: () =>
            import(/* webpackChunkName: "addmeeting" */ "../views/videomeeting/addmeeting.vue")
    },
    {
        path: "videomeeting_personalmeeting",
        name: "videomeeting_personalmeeting",
        component: () =>
            import(/* webpackChunkName: "personalmeeting" */ "../views/videomeeting/personalmeeting.vue")
    },
    {
        path: "videomeeting_enterprisemeeting",
        name: "videomeeting_enterprisemeeting",
        component: () =>
            import(/* webpackChunkName: "personalmeeting" */ "../views/videomeeting/enterprisemeeting.vue")
    }
];