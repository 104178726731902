export default [
    {
        path: "outside_terrace",
        name: "outside_terrace",
        component: () =>
            import(
                /* webpackChunkName: "terrace" */ "../views/outside/terrace.vue"
                ),
    },
    {
        path: "outside_camera",
        name: "outside_camera",
        component: () =>
            import(
                /* webpackChunkName: "camera" */ "../views/outside/camera.vue"
                ),
    },
    {
        path: "outside_event",
        name: "outside_event",
        component: () =>
            import(
                /* webpackChunkName: "event" */ "../views/outside/event.vue"
                ),
    },
];