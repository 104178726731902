export const calcDate = (date1, date2) => {
  let date3 = date2 - date1;

  let days = Math.floor(date3 / (24 * 3600 * 1000));

  let leave1 = date3 % (24 * 3600 * 1000); //计算天数后剩余的毫秒数
  let hours = Math.floor(leave1 / (3600 * 1000));

  let leave2 = leave1 % (3600 * 1000); //计算小时数后剩余的毫秒数
  let minutes = Math.floor(leave2 / (60 * 1000));

  let leave3 = leave2 % (60 * 1000); //计算分钟数后剩余的毫秒数
  let seconds = Math.round(date3 / 1000);
  return {
    leave1,
    leave2,
    leave3,
    days: days,
    hours: hours,
    minutes: minutes,
    seconds: seconds,
  };
};

/**
 * 日期格式化
 */
export function dateFormat(date, format) {
  if (/^\d{4}-\d{2}-\d{2}$/.test(date)) return date;
  format = format || "yyyy-MM-dd hh:mm:ss";

  if (date !== "Invalid Date") {
    let o = {
      "M+": date.getMonth() + 1, //month
      "d+": date.getDate(), //day
      "h+": date.getHours(), //hour
      "m+": date.getMinutes(), //minute
      "s+": date.getSeconds(), //second
      "q+": Math.floor((date.getMonth() + 3) / 3), //quarter
      S: date.getMilliseconds(), //millisecond
    };
    if (/(y+)/.test(format))
      format = format.replace(
        RegExp.$1,
        (date.getFullYear() + "").substr(4 - RegExp.$1.length)
      );
    for (let k in o)
      if (new RegExp("(" + k + ")").test(format))
        format = format.replace(
          RegExp.$1,
          RegExp.$1.length === 1
            ? o[k]
            : ("00" + o[k]).substr(("" + o[k]).length)
        );
    return format;
  }
  return "";
}
/**
 * 获取周几
 */
export function getWeek(date) {
  var week;
  if (date.getDay() == 0) week = "星期日";
  if (date.getDay() == 1) week = "星期一";
  if (date.getDay() == 2) week = "星期二";
  if (date.getDay() == 3) week = "星期三";
  if (date.getDay() == 4) week = "星期四";
  if (date.getDay() == 5) week = "星期五";
  if (date.getDay() == 6) week = "星期六";
  return week;
}

export function getNowTime() {
  function addZero(s) {
    return s < 10 ? ('0' + s) : s;
  }
  var date = new Date();
  //年 getFullYear()：四位数字返回年份
  var year = date.getFullYear();  //getFullYear()代替getYear()
  //月 getMonth()：0 ~ 11
  var month = date.getMonth() + 1;
  //日 getDate()：(1 ~ 31)
  var day = date.getDate();
  //时 getHours()：(0 ~ 23)
  var hour = date.getHours();
  //分 getMinutes()： (0 ~ 59)
  var minute = date.getMinutes();
  //秒 getSeconds()：(0 ~ 59)
  var second = date.getSeconds();

  var time = addZero(hour) + ':' + addZero(minute) + ':' + addZero(second);
  return time;
}



export function changeTime(timestamp){
  var now = new Date(parseFloat(timestamp * 1000));
  var year=now.getFullYear();
  var month=now.getMonth()+1;
  var date=now.getDate();
  if (month >= 1 && month <= 9) {
    month = "0" + month;
  }
  if (date >= 0 && date <= 9) {
    date = "0" + date;
  }
  var hour=now.getHours();
  var minute=now.getMinutes();
  var second=now.getSeconds();
  if (hour >= 1 && hour <= 9) {
    hour = "0" + hour;
  }
  if (minute >= 0 && minute <= 9) {
    minute = "0" + minute;
  }
  if (second >= 0 && second <= 9) {
    second = "0" + second;
  }
  return year+"-"+month+"-"+date+" "+hour+":"+minute+":"+second;
}



export function formatDayTime(val) {
  if(val) {
    let date = new Date(val)
    let Y = date.getFullYear();
    let M = date.getMonth() + 1;
    let D = date.getDate();

    if(M < 10) {
      M = '0' + M;
    }
    if(D < 10) {
      D = '0' + D;
    }

    return Y + '-' + M + '-' + D ;
  } else {
    return '';
  }
}

