import { ElMessage } from "element-plus";
import { getMenuPerm } from "@/api/system/menu";
import { validatenull } from "@/util/util";
import { getStore } from "@/util/util";

const menu = {
  state: {
    menuAll: getStore("menuAll") || [],
    btn_perms: getStore("btn_perms") || [],
    activemenuInfo: getStore("activemenuInfo") || {},
    title: ""
  },
  mutations: {
    GET_TITLE: (state, data)=>{
      state.title = data;
    },
    SET_MENU: (state, menu) => {
      state.menuAll = menu;
      window.localStorage.setItem("menuAll", JSON.stringify(menu));
    },
    SET_Btnperms: (state, btn_perms) => {
      state.btn_perms = btn_perms;
      window.localStorage.setItem("btn_perms", JSON.stringify(btn_perms));
    },
    SET_activemenuInfo: (state, activemenuInfo) => {
      state.activemenuInfo = activemenuInfo;
      window.localStorage.setItem(
        "activemenuInfo",
        JSON.stringify(activemenuInfo)
      );
    },
  },
  actions: {
    //获取顶部菜单
    GetPermMenu({ commit, dispatch }) {
      return new Promise((resolve) => {
        getMenuPerm().then((res) => {
          console.log(res);
          const menu = res.data.data.menuLists || [];
          const btn = res.data.data.buttonLists || [];
          commit("SET_MENU", menu);
          commit("SET_Btnperms", btn);
          resolve(res);
        });
      });
    },


  },
};
export default menu;
