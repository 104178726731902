export default [
    {
        path: "fireproof_phonetics",
        name: "fireproof_phonetics",
        component: () =>
            import(
                /* webpackChunkName: "phonetics" */ "../views/fireproof/phonetics.vue"
                ),
    },
    {
        path: "fireproof_account",
        name: "fireproof_account",
        component: () =>
            import(
                /* webpackChunkName: "account" */ "../views/fireproof/account.vue"
                ),
    },
];