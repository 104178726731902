import { createRouter, createWebHistory } from "vue-router";
import Home from "../views/Home.vue";
import Login from "../components/login.vue";
import content from "../views/content.vue";
import terminalRegulate from "./terminalRegulate.js";
import equipment from "./equipment";
import editionupgrade from "./editionupgrade";
import motorroom from "./motorroom";
import orchestration from "./orchestration";
import profile from "./profile";
import schedule from "./schedule";
import safe from "./safe";
import devops from "./devops";
import resourceMana from "./resourceMana";
import fireproof from "./fireproof";
import popularization from './popularization';
import videomeeting from './videomeeting';
import outside from "./outside";
import { getToken, getverifyToken } from "../util/auth";

const routes = [
  {
    path: "/bigscreen/patrol",
    name: "bigscreen_patrol",
    component: () =>
        import(/* webpackChunkName: "patrol" */ "../views/bigscreen/patrol")
  },
  {
    path: "/bigscreen/jydp",
    name: "bigscreen_jydp",
    component: () =>
        import(/* webpackChunkName: "jydp" */ "../views/bigscreen/jydp")
  },
  {
    path: "/bigscreen/show",
    name: "bigscreen_show",
    component: () =>
      import(/* webpackChunkName: "show" */ "../views/bigscreen/show")
  },
  {
    path: "/bigscreen/bigmap",
    name: "bigscreen_bigmap",
    component: () =>
      import(/* webpackChunkName: "bigmap" */ "../views/bigscreen/bigmap")
  },
  {
    path: "/bigscreen/synopsis",
    name: "bigscreen_synopsis",
    component: () =>
      import(/* webpackChunkName: "synopsis" */ "../views/bigscreen/synopsis")
  }, {
    path: "/bigscreen/viodesynopsis",
    name: "bigscreen_viodesynopsis",
    component: () =>
      import(/* webpackChunkName: "viodesynopsis" */ "../views/bigscreen/viodesynopsis")
  },
  {
    path: "/bigscreen/effect",
    name: "bigscreen_effect",
    component: () =>
      import(/* webpackChunkName: "effect" */ "../views/bigscreen/effect")
  }, {
    path: "/bigscreen/machineMonitor",
    name: "bigscreen_machineMonitor",
    component: () =>
      import(/* webpackChunkName: "effect" */ "../views/bigscreen/machineMonitor")
  }, {
    path: "/bigscreen/systemMonitor",
    name: "bigscreen_systemMonitor",
    component: () =>
      import(/* webpackChunkName: "effect" */ "../views/bigscreen/systemMonitor")
  },
  {
    path: "/",
    name: "Home",
    redirect: "/index"
    // component: Home
  },
  {
    path: "/login",
    name: "Login",
    component: () =>
      import(/* webpackChunkName: "about" */ "../components/login.vue")
  },
  {
    path: "/about",
    name: "About",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/About.vue")
  },
  {
    path: "/index",
    name: "Index",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/index.vue")
  },
  {
    path: "/content",
    name: "content",
    component: content,
    meta: {
      requiresAuth: true
    },
    children: [
      {
        path: "yuwu_historymeeting",
        name: "yuwu_historymeeting",
        component: () =>
            import(/* webpackChunkName: "historyMeeting" */ "../views/yuwu/historymeeting.vue")
      },
      {
        path: "yuwu_meeting",
        name: "yuwu_meeting",
        component: () =>
            import(/* webpackChunkName: "meeting" */ "../views/yuwu/meeting.vue")
      },
      {
        path: "eventmanage_eventlist",
        name: "eventmanage_eventlist",
        component: () =>
            import(/* webpackChunkName: "eventlist" */ "../views/eventmanage/eventlist.vue")
      },
      {
        path: "detectors_list",
        name: "detectors_list",
        component: () =>
            import(/* webpackChunkName: "failurerecovery" */ "../views/detectors/list.vue")
      },
      {
        path: "detectors_manage",
        name: "detectors_manage",
        component: () =>
            import(/* webpackChunkName: "failurerecovery" */ "../views/detectors/manage.vue")
      },
      {
        path: "fault_failurerecovery",
        name: "fault_failurerecovery",
        component: () =>
            import(/* webpackChunkName: "failurerecovery" */ "../views/fault/failurerecovery.vue")
      },
      {
        path: "bigscreen_dpzs",
        name: "bigscreen_dpzs",
        component: () =>
          import(/* webpackChunkName: "about" */ "../views/bigscreen/dpzs.vue")
      },
      {
        path: "system_region",
        name: "system_region",
        component: () =>
          import(/* webpackChunkName: "about" */ "../views/system/region.vue")
      },
      {
        path: "system_menu",
        name: "system_menu",
        component: () =>
          import(/* webpackChunkName: "about" */ "../views/system/menu.vue")
      },
      {
        path: "system_roles",
        name: "system_roles",
        component: () =>
          import(/* webpackChunkName: "about" */ "../views/system/roles.vue")
      },
      {
        path: "system_configuration",
        name: "system_configuration",
        component: () =>
          import(/* webpackChunkName: "configuration" */ "../views/system/configuration.vue")
      },
      {
        path: "system_editioninfo",
        name: "system_editioninfo",
        component: () =>
            import(/* webpackChunkName: "configuration" */ "../views/system/editioninfo.vue")
      },
      {
        path: "system_user",
        name: "system_user",
        component: () =>
            import(/* webpackChunkName: "about" */ "../views/system/user.vue")
      }, {
        path: "system_messageConfig",
        name: "system_messageConfig",
        component: () =>
          import(/* webpackChunkName: "about" */ "../views/system/messageConfig.vue")
      }, {
        path: "system_equipInfo",
        name: "system_equipInfo",
        component: () =>
          import(/* webpackChunkName: "about" */ "../views/system/equipInfo.vue")
      }, {
        path: "system_plateInfo",
        name: "system_plateInfo",
        component: () =>
          import(/* webpackChunkName: "about" */ "../views/system/plateInfo.vue")
      }, {
        path: "system_boastInfo",
        name: "system_boastInfo",
        component: () =>
          import(/* webpackChunkName: "about" */ "../views/system/boastInfo.vue")
      }, {
        path: "system_databaseRecords",
        name: "system_databaseRecords",
        component: () =>
          import(/* webpackChunkName: "about" */ "../views/system/databaseRecords.vue")
      }, {
        path: "system_databaseSettings",
        name: "system_databaseSettings",
        component: () =>
          import(/* webpackChunkName: "about" */ "../views/system/databaseSettings.vue")
      },
      {
        path: "basic_vendor",
        name: "basic_vendor",
        component: () =>
          import(/* webpackChunkName: "about" */ "../views/basic/vendor.vue")
      },
      {
        path: "basic_equipType",
        name: "basic_equipType",
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../views/basic/equipType.vue"
            )
      },
      {
        path: "basic_tvsubtitle",
        name: "basic_tvsubtitle",
        component: () =>
            import(
                /* webpackChunkName: "about" */ "../views/basic/tvsubtitle.vue"
                )
      },
      {
        path: "basic_bluetooth",
        name: "basic_bluetooth",
        component: () =>
            import(
                /* webpackChunkName: "about" */ "../views/basic/bluetooth.vue"
                )
      },
      {
        path: "basic_deviceModel",
        name: "basic_deviceModel",
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../views/basic/deviceModel.vue"
            )
      },
      {
        path: "basic_equipmanage",
        name: "basic_equipmanage",
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../views/basic/equipmanage.vue"
            )
      }, {
        path: "basic_adapterBroadcast",
        name: "basic_adapterBroadcast",
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../views/basic/adapterBroadcast.vue"
            )
      },
      {
        path: "basic_imagetextBroadcast",
        name: "basic_imagetextBroadcast",
        component: () =>
            import(
                /* webpackChunkName: "about" */ "../views/basic/imagetextBroadcast.vue"
                )
      },{
        path: "basic_loudspeaker",
        name: "basic_loudspeaker",
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../views/basic/loudspeaker.vue"
            )
      },
      {
        path: "basic_terminalpoint",
        name: "basic_terminalpoint",
        component: () =>
            import(
                /* webpackChunkName: "about" */ "../views/basic/terminalpoint.vue"
                )
      },
      {
        path: "basic_startBroadcast",
        name: "basic_startBroadcast",
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../views/basic/startBroadcast.vue"
            )
      },
      {
        path: "dailyBroad_mediaResources",
        name: "dailyBroad_mediaResources",
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../views/dailyBroad/mediaResources.vue"
            )
      },
      {
        path: "dailyBroad_mediaRreview",
        name: "dailyBroad_mediaRreview",
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../views/dailyBroad/mediaRreview.vue"
            )
      },
      {
        path: "dailyBroad_levelResource",
        name: "dailyBroad_levelResource",
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../views/dailyBroad/levelResource.vue"
            )
      },
      {
        path: "dailyBroad_radioShow",
        name: "dailyBroad_radioShow",
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../views/dailyBroad/radioShow.vue"
            )
      },
      {
        path: "dailyBroad_programReview",
        name: "dailyBroad_programReview",
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../views/dailyBroad/programReview.vue"
            )
      },
      {
        path: "dailyBroad_levelProgramReview",
        name: "dailyBroad_levelProgramReview",
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../views/dailyBroad/levelProgramReview.vue"
            )
      },
      {
        path: "viode_test",
        name: "viode_test",
        component: () =>
          import(/* webpackChunkName: "about" */ "../views/viode/test.vue")
      },
      {
        path: "viode_show",
        name: "viode_show",
        component: () =>
          import(/* webpackChunkName: "about" */ "../views/viode/show.vue")
      },
      {
        path: "devops_control",
        name: "devops_control",
        component: () =>
          import(/* webpackChunkName: "about" */ "../views/devops/control")
      },
      {
        path: "screen_deploy",
        name: "screen_deploy",
        component: () =>
          import(/* webpackChunkName: "about" */ "../views/screen/deploy")
      },
      {
        path: "screen_dispose",
        name: "screen_dispose",
        component: () =>
          import(/* webpackChunkName: "about" */ "../views/screen/dispose")
      },
      {
        path: "topological_setup",
        name: "topological_setup",
        component: () =>
          import(/* webpackChunkName: "topological_setup" */ "../views/topological/setup")
      },{
        path: "userinfo",
        name: "userinfo",
        component: () =>
          import(/* webpackChunkName: "userinfo" */ "../views/userinfo")
      },
      ...terminalRegulate,
      ...orchestration,
      ...schedule,
      ...equipment,
      ...editionupgrade,
      ...motorroom,
      ...safe,
      ...devops,
      ...profile,
      ...resourceMana,
      ...fireproof,
      ...popularization,
      ...videomeeting,
      ...outside
    ]
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

router.beforeEach((to, from, next) => {
  if (to.path !== "/login") {
    let token = getToken();
    if (!token) {
      next({ path: "/login" });
    } else {
      next();
    }
  } else {
    next();
  }
});
// router.afterEach(() => {
//   window.scroll(0, 0);
// });
export default router;
