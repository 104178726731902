<template>
  <div :style="{ height: height }" class="fill">
    <header class="title">{{title}}</header>
    <main class="main">
      <div class="form">
        <header class="header">用户登录</header>
        <div class="user">
          <div class="l">
            <img src="../../src/assets/user.png" />
          </div>
          <div class="r">
            <input placeholder="请输入账号" v-model="form.userNo" />
          </div>
        </div>

        <div class="user">
          <div class="l">
            <img src="../../src/assets/pwd.png" />
          </div>
          <div class="r">
            <input type="password" placeholder="请输入密码" v-model="form.password"/>
          </div>
        </div>

<!--        <Drag @success="isdrag = $event" />-->

        <div class="login" @click="onSubmit">登录</div>
      </div>
    </main>
    <!--    <div class="loginForm">-->
    <!--      <el-form :model="form" label-width="120px" label-position="left">-->
    <!--        <el-form-item label="账号">-->
    <!--          <el-input v-model="form.userNo" placeholder="请输入账号"></el-input>-->
    <!--        </el-form-item>-->

    <!--        <el-form-item label="密码">-->
    <!--          <el-input v-model="form.password" placeholder="请输入密码"></el-input>-->
    <!--        </el-form-item>-->
    <!--        <el-form-item>-->
    <!--          <el-button type="primary" @click="onSubmit">登录</el-button>-->
    <!--        </el-form-item>-->
    <!--      </el-form>-->
    <!--    </div>-->

    <div class="verify" v-if="isverify">
      <Dragverify width="392" @chose="isverify = false" @passcallback="passcallback" class="ver" />
    </div>
  </div>
</template>

<script>
import Dragverify from "../../src/components/Dragverify";
// import Drag from "../../src/components/drag";
import { mapGetters } from 'vuex';
import {getData} from "@/api/system/user";
import {ElNotification} from "element-plus";
export default {
  name: "login",
  components: {
    // Drag,
    Dragverify,
  },
  data() {
    return {
      isverify:false,
      // isdrag: false, //滑块是否验证成功
      height: "",
      form: {
        userNo: "",
        password: "",
      },
    };
  },
  created() {
    let height =
      window.innerHeight ||
      document.documentElement.clientHeight ||
      document.body.clientHeight;
    this.height = height + "px";
  },
  computed:{
    ...mapGetters(["title"]),
  },
  methods: {
    passcallback(){
      let { userNo, password } = this.form;
      this.$store.dispatch("handlelogin", { userNo, password }).then((res) => {
            getData().then(res2=>{
              ElNotification({
                title: "消息提示",
                position: "bottom-right",
                dangerouslyUseHTMLString: true,
                message: `<div>${res2.data.data.msg}</div>`
              });
            })
            this.$router.replace("/index");
        }).catch((err) => {
      });
    },
    onSubmit() {
      let { userNo, password } = this.form;

      if (!userNo)
        return this.$message({ message: "请先输入账号", type: "warning" });
      if (!password)
        return this.$message({ message: "请先输入密码", type: "warning" });

      this.isverify = true;
    },
  },
};
</script>

<style scoped lang="less">
.verify{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  background: rgba(#071D1B,.6);
  height: 100%;
  width: 100%;
  .ver{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
  }
}

.fill {
  background: url("../../src/assets/loginbg.jpg") no-repeat center;
  background-size: 100% 100%;
  margin: 0;
  padding: 0;
  width: 100%;
}
.fill .title {
  background: url("../../src/assets/title.png") no-repeat center;
  background-size: 100% 100%;
  width: 1920px;
  height: 103px;
  margin: 0 auto;
  font-size: 46px;
  color: #FFFFFF;
  font-weight: bold;
  text-align: center;
}

.fill .main {
  position: absolute;
  top: 55%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: url("../../src/assets/login.png") no-repeat center;
  background-size: 100% 100%;
  width: 568px;
  height: 602px;
}

.fill .main .form {
  position: absolute;
  margin-top: 10px;
  width: 345px;
  height: 338px;
  top: 102px;
  left: 50%;
  transform: translate(-50%, 0%);
  display: flex;
  flex-direction: column;
}

.fill .main .form .header {
  font-size: 39px;
  font-weight: 400;
  color: #ffffff;
  text-align: center;
  margin-bottom: 40px;
}

.fill .main .form .user {
  width: 100%;
  height: 63px;
  border: 1px solid #b4b4b4;
  display: flex;
  align-items: center;
  margin-bottom: 30px;
  .l {
    flex-basis: 70px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    img{
      width: 22px;
      height: 25px;
    }
  }
  .r {
    flex-basis: calc(100% - 70px);
    height: 100%;
    display: flex;
    align-items: center;
    input {
      width: 100%;
      height: 80%;
      font-size: 20px;
      background: #002e23;
      color: #b4b4b4;
    }
  }
}

.login {
  background: #01ffc1;
  width: 100%;
  height: 70px;
  text-align: center;
  color: #ffffff;
  font-size: 26px;
  line-height: 70px;
  font-weight: 600;
  user-select: none;
  border-radius: 2px;
  &:active {
    transform: scale(1.1);
    transition: all 0.2s;
  }
  &:hover {
    cursor: pointer;
  }
}
</style>
