import Cookies from "js-cookie";

const TokenKey = "accessToken";
const verifyToken = "verifyToken";

export function getToken() {
  return Cookies.get(TokenKey);
}

export function getverifyToken() {
  return Cookies.get(verifyToken);
}

export function setaccessToken(token) {
  return Cookies.set(TokenKey, token, { expires: 1 });
}

export function setverifyToken(token) {
  return Cookies.set(verifyToken, token, { expires: 1 });
}

export function clearCookie() {
  Cookies.remove(TokenKey);
  Cookies.remove(verifyToken);
}