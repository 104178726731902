export default [
    {
        path: "safe_sensitive",
        name: "safe_sensitive",
        component: () =>
            import(/* webpackChunkName: "about" */ "../views/safe/sensitive.vue")
    },
    {
        path: "safe_pwd",
        name: "safe_pwd",
        component: () =>
            import(/* webpackChunkName: "about" */ "../views/safe/pwd.vue")
    },{
        path: "safe_updatecertificate",
        name: "safe_updatecertificate",
        component: () =>
            import(/* webpackChunkName: "about" */ "../views/safe/updatecertificate.vue")
    },
];