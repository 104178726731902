import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import axios from "@/api/axios";

import elementPlus from "element-plus";
import locale from "element-plus/lib/locale/lang/zh-cn";
import * as icon from "@element-plus/icons";
import "element-plus/dist/index.css";
import "./css/public.css";
import "./css/css.less";
import "lib-flexible-computer";
import { TreeSelect } from "ant-design-vue";
import { Icon } from "ant-design-vue";
import basicContainer from "./components/basic-container";
import scroll from 'vue-seamless-scroll/src'
import basicTable from "./components/basic-table";
import VueClipboard from 'vue-clipboard2'
import JsonExcel from 'vue-json-excel/JsonExcel'
const app = createApp(App);
app.component('downloadExcel', JsonExcel)
app.component("TreeSelect", TreeSelect);
app.component("Icon", Icon);
app.config.productionTip = false;
app.config.globalProperties.$axios = axios;
for (let iconName in icon) {
  app.component(iconName, icon[iconName]);
}
// let option = new VueSocketIO({
//   debug: true,
//   connection: SocketIO("ws://192.168.3.29:9888",{
//       autoConnect: false,
//     }
//   ),
//   options: { path: "/websocket/bh", transports: ["websocket", "polling", "flashsocket"] }
// });
app.component("basicContainer", basicContainer);
app.component("basicTable", basicTable);
app.use(store).use(router).use(elementPlus, { locale }).use(scroll).use(VueClipboard).mount("#app");
