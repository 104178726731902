export default [
    {
        path: "equipment_manage",
        name: "equipment_manage",
        component: () =>
            import(
                /* webpackChunkName: "about" */ "../views/equipment/manage.vue"
                ),
    }, {
        path: "basic_unregisteredDevice",
        name: "basic_unregisteredDevice",
        component: () =>
            import(
                /* webpackChunkName: "about" */ "../views/basic/unregisteredDevice.vue"
                ),
    },
    {
        path: "equipment_portable",
        name: "equipment_portable",
        component: () =>
            import(
                /* webpackChunkName: "about" */ "../views/equipment/portable.vue"
                ),
    },
];