<template>
  <div class="headers">
    <div class="top_left">
      <img src="../assets/leftlogo.png">
      <div class="text">
        <span class="title">{{title}}</span>
      </div>
    </div>

    <div class="up" @click="toLeft" v-if="isShow"></div>

    <div class="top_center" ref="topCenter">
      <ul class="menuUl" ref="menuUl" :style="{ left: positionLeft + 'px' }">
        <li
            ref="menuli"
            class="menuliCl"
            :class="{
            menuLiSelected: activeIndex == 0,
            menuLi: activeIndex !== 0,
          }"
            @click="getHome"
        >首页
        </li>
        <li
          v-for="(item, index) in menuList"
          :key="index"
          class="menuliCl"
          :style="{transform: `translate(${(index+1) * -25}px, 0)`}"
          :class="{
            menuLiSelected: activeIndex == index+1,
            menuLi: activeIndex !== index+1,
          }"
          @click="getsecondPage(item.id, index+1)"
        >
          {{ item.name }}
        </li>
      </ul>
    </div>


    <div class="down" @click="toRight"  v-if="isShow"></div>


    <div class="top_right">
      <div class="timer">
        <div>{{ timeArr[1] }}</div>
        <span>{{ timeArr[0] }}</span>
        <div>{{ formatweek }}</div>
      </div>
      <el-dropdown>
        <img src="../assets/userImg.png" />
        <template #dropdown>
          <el-dropdown-menu>
            <el-dropdown-item  @click="getMyInfo">个人信息</el-dropdown-item>
            <el-dropdown-item  @click="reset">修改密码</el-dropdown-item>
            <el-dropdown-item  @click="handleLogout">退出登录</el-dropdown-item>
          </el-dropdown-menu>
        </template>
      </el-dropdown>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
import { getMenuPerm } from "@/api/system/menu";
import { dateFormat, getWeek } from "../util/date";
import { useRouter } from "vue-router";
import {ref, onMounted, nextTick, reactive, toRefs} from "vue";
import middle from "./middle";
import { logout } from "@/api/system/user";
import { ElMessageBox, ElMessage } from "element-plus";
import { clearCookie } from "@/util/auth";

export default {
  name: "header",
  setup() {
    const router = useRouter();
    let menuUl = ref();
    let rightBtn = ref(true);
    let topCenter = ref();
    let positionLeft = ref(0);
    let menuli = ref(null);
    let o = 0;

    function toRight() {
      let _child = menuUl.value.children.length;
      let _rect = topCenter.value.getBoundingClientRect();
      let _width = _rect.width;
      let _lirect = menuli.value.getBoundingClientRect();
      let _liwidth = _lirect.width;
      let num = _width / (_liwidth - 25) | 0;
      if(o >= _child - num) return;
      positionLeft.value = -(++o * 118);
    }

    function toLeft() {
      if(o == 0) return;
      positionLeft.value = -(--o * 118);
    }

    function handleLogout() {
      ElMessageBox.confirm(
        "确认退出登陆?",
        "提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "Warning"
        }
      )
        .then(() => {
          clearCookie();
          router.replace("/login");
          if (res.data.success) {
            ElMessage({
              type: "success",
              message: "操作成功！"
            });
          }
          return logout();
        })

    }

    function getMyInfo() {
      router.push("/content/userinfo")
    }

    function getHome(){
      router.push("/");
    }



    return { router, toLeft, menuli, toRight, menuUl, topCenter, positionLeft, rightBtn, handleLogout, getMyInfo, getHome };
  },
  computed: {
    ...mapGetters(["activemenuInfo", 'title'])
  },
  data() {
    return {
      isShow: false,
      timeArr: [],
      formatweek: "",
      menuList: []
    };
  },

  created() {
    this.$store.dispatch("GetPermMenu").then((res) => {
      if (res.data.success) {
        middle.emit("getMenuLL", res.data.data.menuLists);
        const data = res.data.data.menuLists;
        let arr = [];
        data.map((item) => {
          if (item.type == 0) {
            let { icon, name, id, url } = item;
            arr.push({ icon, name, id, url });
          }
        });
        this.menuList = arr;
        nextTick(()=>{
          let width = this.$refs['topCenter'].getBoundingClientRect().width;
          let rectWidth = this.$refs['menuli'].getBoundingClientRect().width;
          let num = width / (rectWidth - 25) | 0;
          if(this.menuList.length + 1 > num) this.isShow = true;
        })
      }
    });
    this.activeIndex = this.activemenuInfo.index;

    this.gettime();
    setInterval(this.gettime, 1000);
  },
  methods: {
    ...mapMutations(["MUTATIONA_PWD_DIALOG"]),
    reset(){
      this.MUTATIONA_PWD_DIALOG(true)
    },
    gettime() {
      let time = new Date();
      let formattime = dateFormat(time, "yyyy年MM月dd日 hh:mm:ss");
      this.timeArr = formattime.split(" ");
      this.formatweek = getWeek(time);
    },
    getsecondPage(id, index) {
      window.localStorage.setItem(
        "activemenuInfo",
        JSON.stringify({ id, index })
      );
      this.activeIndex = index;
      middle.emit("getmenuId", id);
    }
  }
};
</script>

<style scoped lang="less">
//@keyframes move{
//  0%{
//    opacity: 0;
//  }
//  50%{
//    opacity: .5;
//  }
//  100%{
//    opacity: 1;
//  }
//}
.up{
  position: absolute;
  top: 50%;
  transform: translate(0px, -50%);
  left: calc(24% - 43px);
  z-index: 999;
  &::before{
    content: "";
    display: block;
    width: 63px;
    height: 75px;
    transform:rotate(180deg);
    background: url("../assets/tonextPage.png") no-repeat center;
    background-size: 100% 100%;
  }
}

.down{
  position: absolute;
  top: 50%;
  transform: translate(0px, -50%);
  left: 86%;
  z-index: 999;
  &::before{
    content: "";
    display: block;
    width: 63px;
    height: 75px;
    background: url("../assets/tonextPage.png") no-repeat center;
    background-size: 100% 100%;
  }
}

@keyframes scan {
  0% {
    left: -50px;
    transform: skew(-37deg, -2deg);
  }
  50%{
    opacity: 1;
  }
  100% {
    opacity: 0;
    transform: skew(-37deg, -2deg);
    right: 170px;
  }
}

.headers {
  background: url("../assets/headerBg.png") no-repeat center;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  position: relative;
}

.top_left {
  width: 24%;
  height: 100%;
  display: flex;
  align-items: center;
  img{
    display: block;
    width: 55px;
    height: 49px;
    margin: 0 20px;
  }
  .text{
    display: flex;
    flex-direction: column;
    .title{
      font-weight: 600;
      font-size: 30px;
      color: #FFFFFF;
    }
  }
  /*background: url("../assets/headerTitle2.png") no-repeat center;*/
}

.top_center {
  overflow: hidden;
  position: relative;
  width: 62%;
  height: 100%;
}

.menuUl {
  margin: 0;
  transition: all 1s;
  padding: 0;
  position: absolute;
  top: 0;
  //width: calc(100% - 20px);
  /*width: 100%;*/
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  overflow-x: hidden;
}

.menuLi {
  background: url("../assets/menuBg.png") no-repeat center;
}


.top_left {
  width: 24%;
  height: 100%;
  display: flex;
  align-items: center;
  img{
    display: block;
    width: 55px;
    height: 49px;
    margin: 0 20px;
  }
  .text{
    display: flex;
    flex-direction: column;
    .title{
      font-weight: 600;
      font-size: 30px;
      color: #FFFFFF;
    }
  }
  /*background: url("../assets/headerTitle2.png") no-repeat center;*/
}

.menuUl {
  margin: 0;
  transition: all 1s;
  padding: 0;
  position: absolute;
  top: 0;
  /*width: 100%;*/
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  overflow-x: hidden;
}

.menuLi {
  background: url("../assets/menuBg.png") no-repeat center;
}

.menuliCl {
  cursor: pointer;
  line-height: 41px;
  width: 143px;
  /*list-style: none;*/
  height: 39px;
  font-size: 20px;
  font-family: Source Han Sans SC;
  font-weight: 300;
  color: #32B89C;
}

.menuLiSelected {
  color: #ffffff;
  background: url("../assets/menuSelected.png") no-repeat center;
  background-size: contain;
  position: relative;
  overflow: hidden;
  &::before{
    content: "";
    background: linear-gradient(to bottom, transparent, #FFFFFF);
    animation: scan 2s ease-in-out infinite;
    position: absolute;
    width: 10px;
    height: 41px;
    left: 120%;
    top: -3px;
    z-index: 10;
  }
}

.menuliCl:hover {
  color: #00ffc6;
}

.top_right {
  position: absolute;
  right: 0;
  width: 10%;
  height: 100%;
  display: flex;
  /*justify-content: space-around;*/
  align-items: center;
}


.el-dropdown-menu {
  background-color: #0a2b28;
}

.menuliCl:hover {
  color: #00ffc6;
}



.timer {
  width: 70%;
  display: flex;
  flex-direction: column;
  margin-right: 20px;
}

.timer span, .timer>div {
  text-align: right;
  font-size: 14px;
  font-family: Dotted Songti Circle;
  font-weight: 400;
  color: #00ffc0;
}

.el-dropdown-menu {
  background-color: #0a2b28;
}
</style>
