import axios from "./axios"; // 引用axios
import { encrypton } from "@/util/rsa";

export const loginByUsername = (params) => {
  params.password = encrypton(params.password);
  return axios({
    url: "/api/loginData",
    method: "post",
    params: params,
  });
};
