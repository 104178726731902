<template>
  <div class="basic-container" :class="{ 'basic-container--block': block }">
    <!--    <el-card>-->
    <slot></slot>
    <!--    </el-card>-->
  </div>
  <!--  <div class="basic-container" >-->
  <!--    <slot></slot>-->
  <!--  </div>-->
</template>

<script>
import { clientHeight } from "@/util/util";

export default {
  name: "basic-container",
  data() {
    return {};
  },
};
</script>

<style scoped lang="scss">
.basic-container {
  padding: 10px 6px;
  box-sizing: border-box;

  .el-card {
    height: 100%;
    width: 100%;
    background: #061c1a;
    border: none;
  }

  &:first-child {
    padding-top: 0;
  }
}
</style>
