<template>
  <el-scrollbar style="height: 100%">
    <div v-if="!menuList.length" class="avue-sidebar--tip">暂无菜单</div>
    <el-menu
      unique-opened
      @select="handleSelect"
      :collapse="false"
      active-text-color="#fff"
      background-color="#0c3834"
      class="el-menu-vertical-demo"
      text-color="#95D7C8"
      :default-openeds="openeds"
      default-active="1-0"
      v-if="issss"
    >
      <div class="menu-wrapper">
        <template v-for="(item, index) in menuList">
          <!-- 无子菜单-->
          <router-link
            :to="item.url"
            v-if="!item.children || !item.children.length"
            :key="item.id"
          >
            <el-menu-item :index="index + ''">
              <template #title>
                <!--                <component :is="item.icon"></component>-->
                <div
                  :class="{ sidebarMenu: activeIndex == index + '' }"
                  class="sidebarNormal"
                >
                  {{ item.name }}
                </div>
              </template>
            </el-menu-item>

          </router-link>
          <!-- 有二级子菜单-->

          <el-sub-menu
            v-else-if="item.children && item.children.length"
            :index="index + '' + 1"
            :key="item.id"
          >
            <template #title>
              <component :is="item.icon"></component>
              <div
                :class="{ sidebarMenu: activeIndex == index + '' }"
                class="sidebarNormal"
              >
                {{ item.name }}
              </div>
            </template>

            <template v-for="(child, cindex) in item.children">
              <!-- 无三级子菜单-->
              <router-link
                :to="child.url"
                v-if="!child.children || !child.children.length"
                :key="child.id"
              >
                <el-menu-item :index="index + 1 + '-' + cindex">
                  <template #title>
<!--                    <component :is="child.icon"></component>-->
                    <div
                      :class="{
                        sidebarMenu: activeIndex == index + 1 + '-' + cindex,
                      }"
                      class="sidebarNormal"
                    >
                      {{ child.name }}
                    </div>
                  </template>
                </el-menu-item>
              </router-link>
              <!-- 有三级子菜单-->

              <el-sub-menu
                v-else-if="child.children && child.children.length"
                :index="index + 1 + '-' + cindex"
                :key="child.id"
              >
                <template #title>
                  <component :is="child.icon"></component>
                  <div
                    :class="{
                      sidebarMenu: activeIndex == index + 1 + '-' + cindex,
                    }"
                    class="sidebarNormal"
                  >
                    {{ child.name }}
                  </div>
                </template>
                <template v-for="(grandson, gindex) in child.children">
                  <router-link
                    :to="grandson.url"
                    v-if="!grandson.children.length"
                    :key="grandson.id"
                  >
                    <el-menu-item
                      :index="index + 1 + '-' + cindex + '-' + gindex"
                    >
                      <template #title>
                        <!--                        <component :is="grandson.icon"></component>-->
                        <div
                          :class="{
                            sidebarMenu:
                              activeIndex ==
                              index + 1 + '-' + cindex + '-' + gindex,
                          }"
                          class="sidebarNormal"
                        >
                          {{ grandson.name }}
                        </div>
                      </template>
                    </el-menu-item>
                  </router-link>

                  <el-sub-menu
                    v-else-if="grandson.children && grandson.children.length"
                    :index="index + 1 + '-' + cindex + '-' + gindex"
                    :key="grandson.id"
                  >
                    <template #title>
                      <component :is="grandson.icon"></component>
                      <div
                        :class="{
                          sidebarMenu:
                            activeIndex ==
                            index + 1 + '-' + cindex + '-' + gindex,
                        }"
                        class="sidebarNormal"
                      >
                        {{ grandson.name }}
                      </div>
                    </template>
                    <template v-for="(dbgrandson, dindex) in grandson.children">
                      <router-link
                        :to="dbgrandson.url"
                        @click="open(dbgrandson)"
                        v-if="!dbgrandson.children.length"
                        :key="dbgrandson.id"
                      >
                        <el-menu-item
                          :index="
                            index +
                            1 +
                            '-' +
                            cindex +
                            '-' +
                            gindex +
                            '-' +
                            dindex
                          "
                        >
                          <template #title>
                            <!--                            <component :is="dbgrandson.icon"></component>-->
                            <div
                              :class="{
                                sidebarMenu:
                                  activeIndex ==
                                  index +
                                    1 +
                                    '-' +
                                    cindex +
                                    '-' +
                                    gindex +
                                    '-' +
                                    dindex,
                              }"
                              class="sidebarNormal"
                            >
                              {{ dbgrandson.name }}
                            </div>
                          </template>
                        </el-menu-item>
                      </router-link>
                    </template>
                  </el-sub-menu>
                </template>
              </el-sub-menu>
            </template>
          </el-sub-menu>
        </template>
      </div>
    </el-menu>
  </el-scrollbar>
</template>

<script>
// import { validatenull } from "@/util/util";
import { mapGetters } from "vuex";
import middle from "./middle";

export default {
  name: "sidebar",
  data() {
    return {
      openeds:['01'],
      activeIndex: "",
      menuList: [],
      issss:true
    };
  },
  // props: {
  //   menuAll: {
  //     type: Array,
  //     default() {
  //       return [];
  //     }
  //   }
  // },
  computed: {
    ...mapGetters(["menuAll", "activemenuInfo"])
  },
  created() {
    // console.log(this.menuAll);
    // console.log(this.activemenuInfo);
    let obj = this.menuAll.find((item) => item.id == this.activemenuInfo.id);
    this.menuList = obj.children;
  },
  mounted() {
    // middle.on("getMenuLL", (menuAll) => {
    //   this.menuList = menuAll;
    //   console.log(this.menuList);
    // });
    middle.on("getmenuId", (id) => {
      this.$nextTick(()=>{
        let obj = this.menuAll.find((item) => item.id == id);
        this.menuList = obj.children;
        this.activeIndex = "";
        this.$router.push({path:obj.children[0].children[0].url});
        this.issss = false;
        setTimeout(()=>{
          this.issss = true;
        },16)
      })
    });
  },
  methods: {
    handleSelect(key, keyPath) {
      // console.log(key, "key");
      this.activeIndex = key;
    }
  }
};
</script>

<style scoped>
/*.el-menu-item {*/
/*  padding: 0 !important;*/
/*}*/

.sidebarNormal {
  width: 100%;
}
.el-menu-item.is-active{
  background: url("../assets/secPageSelected.png") no-repeat center !important;
}
/*.sidebarMenu {*/
/*  background: url("../assets/secPageSelected.png") no-repeat center;*/
/*}*/

.el-menu {
  --el-menu-border-color: none;
}

/*.el-menu-item{*/
/*  padding: 0 !important;*/
/*}*/
.el-sub-menu__title svg ,.el-menu-item svg{
  width: 50px;
}
::v-deep   .el-sub-menu.is-opened >.el-menu.el-menu--inline{
  --el-menu-bg-color:#122420 !important;
}
</style>
