//定义一些常量
var x_PI = 3.14159265358979324 * 3000.0 / 180.0;
var PI = 3.1415926535897932384626;
var a = 6378245.0;
var ee = 0.00669342162296594323;

export const clientHeight = () => {
  let height =
    window.innerHeight ||
    document.documentElement.clientHeight ||
    document.body.clientHeight;
  return height;
};

export function out_of_china(lng, lat) {
  lat = +lat;
  lng = +lng;
  // 纬度3.86~53.55,经度73.66~135.05
  return !(lng > 73.66 && lng < 135.05 && lat > 3.86 && lat < 53.55);
};

export function transformlat(lng, lat) {
  lat = +lat;
  lng = +lng;
  var ret = -100.0 + 2.0 * lng + 3.0 * lat + 0.2 * lat * lat + 0.1 * lng * lat + 0.2 * Math.sqrt(Math.abs(lng));
  ret += (20.0 * Math.sin(6.0 * lng * PI) + 20.0 * Math.sin(2.0 * lng * PI)) * 2.0 / 3.0;
  ret += (20.0 * Math.sin(lat * PI) + 40.0 * Math.sin(lat / 3.0 * PI)) * 2.0 / 3.0;
  ret += (160.0 * Math.sin(lat / 12.0 * PI) + 320 * Math.sin(lat * PI / 30.0)) * 2.0 / 3.0;
  return ret
};

export function transformlng(lng, lat) {
  lat = +lat;
  lng = +lng;
  var ret = 300.0 + lng + 2.0 * lat + 0.1 * lng * lng + 0.1 * lng * lat + 0.1 * Math.sqrt(Math.abs(lng));
  ret += (20.0 * Math.sin(6.0 * lng * PI) + 20.0 * Math.sin(2.0 * lng * PI)) * 2.0 / 3.0;
  ret += (20.0 * Math.sin(lng * PI) + 40.0 * Math.sin(lng / 3.0 * PI)) * 2.0 / 3.0;
  ret += (150.0 * Math.sin(lng / 12.0 * PI) + 300.0 * Math.sin(lng / 30.0 * PI)) * 2.0 / 3.0;
  return ret
};

export function gcj02towgs84(lng, lat) {
  lat = +lat;
  lng = +lng;
  if (out_of_china(lng, lat)) {
    return [lng, lat]
  } else {
    var dlat = transformlat(lng - 105.0, lat - 35.0);
    var dlng = transformlng(lng - 105.0, lat - 35.0);
    var radlat = lat / 180.0 * PI;
    var magic = Math.sin(radlat);
    magic = 1 - ee * magic * magic;
    var sqrtmagic = Math.sqrt(magic);
    dlat = (dlat * 180.0) / ((a * (1 - ee)) / (magic * sqrtmagic) * PI);
    dlng = (dlng * 180.0) / (a / sqrtmagic * Math.cos(radlat) * PI);
    var mglat = lat + dlat;
    var mglng = lng + dlng;
    // return [lng * 2 - mglng, lat * 2 - mglat]
    return [lat * 2 - mglat,lng * 2 - mglng]
  }
};

export function validatenull(val) {
  if (typeof val == "boolean") {
    return false;
  }
  if (typeof val == "number") {
    return false;
  }
  if (val instanceof Array) {
    if (val.length == 0) return true;
  } else if (val instanceof Object) {
    if (JSON.stringify(val) === "{}") return true;
  } else {
    if (
      val == "null" ||
      val == null ||
      val == "undefined" ||
      val == undefined ||
      val == ""
    )
      return true;
    return false;
  }
  return false;
}

export const getStore = (name = "") => {
  let obj = window.sessionStorage.getItem(name);
  if (validatenull(obj)) obj = window.localStorage.getItem(name);
  if (validatenull(obj)) return;
  try {
    obj = JSON.parse(obj);
  } catch {
    return obj;
  }
  // console.log(obj.dataType);
  // let content;
  // if (obj.dataType == "string") {
  //   content = obj;
  // } else if (obj.dataType == "number") {
  //   content = Number(obj);
  // } else if (obj.dataType == "boolean") {
  //   content = eval(obj);
  // } else if (obj.dataType == "object") {
  //   content = obj;
  // }
  let content = obj;
  return content;
};
export var IframeOnClick = {
  resolution: 200,
  iframes: [],
  interval: null,
  Iframe: function() {
    this.element = arguments[0];
    this.cb = arguments[1];
    this.hasTracked = false;
  },
  track: function(element, cb) {
    this.iframes.push(new this.Iframe(element, cb));
    if (!this.interval) {
      var _this = this;
      this.interval = setInterval(function() {
        _this.checkClick();
      }, this.resolution);
    }
  },
  checkClick: function() {
    if (document.activeElement) {
      var activeElement = document.activeElement;
      for (var i in this.iframes) {
        if (activeElement === this.iframes[i].element) { // user is in this Iframe
          if (this.iframes[i].hasTracked == false) {
            this.iframes[i].cb.apply(window, []);
            this.iframes[i].hasTracked = true;
          }
        } else {
          this.iframes[i].hasTracked = false;
        }
      }
    }
  }
};