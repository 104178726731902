import JSEncrypt from "jsencrypt";

export function encrypton(str) {
  // 注册方法
  let pubKey = `MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQCVowHsjtmfXnbi7MKwQlb1aM/+OcjdiyMSeSK98SSx4SVdF3OtvcjdKYlqgNuUjKRSViwTUwvUA5/Hx6+65wWg8Up+jjUywYRnZ5ZQyZGS2N3/iz33xcIzI995w0kYP2FuwnAkoN796B60r/3mWC7/DvikvecPsrkW1lPnMgGKSQIDAQAB`; // ES6 模板字符串 引用 rsa 公钥
  let encryptStr = new JSEncrypt();
  encryptStr.setPublicKey(pubKey); // 设置 加密公钥
  let data = encryptStr.encrypt(str.toString()); // 进行加密
  return data;
}
