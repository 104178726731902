import { loginByUsername } from "@/api/login";
import { setaccessToken, setverifyToken } from "@/util/auth";
import { ElMessage } from "element-plus";
import { MUTATIONA_PWD_DIALOG } from './mutautionName';

const user = {
  state: {
    isresetPwd: false,
  },
  mutations: {
    set_userVerify(state, params) {
      setaccessToken(params.access_token);
      setverifyToken(params.verify_token);
    },
    [MUTATIONA_PWD_DIALOG](state, payload){
      state.isresetPwd = payload
    },
  },
  actions: {
    handlelogin({ commit }, params) {
      return new Promise((resolve, reject) => {
        loginByUsername(params)
          .then((res) => {
            const data = res.data;
            if (!res.data.success) {
              ElMessage({
                message: data.code.message,
                type: "error",
              });
            } else {
              commit("set_userVerify", data.data);
            }
            resolve();
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
  },
};
export default user;
