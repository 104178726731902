import axios from "axios";
import { getToken, getverifyToken, clearCookie } from "@/util/auth";
import { ElMessage } from "element-plus";
import login from "../components/login";
//默认超时时间
axios.defaults.timeout = 10000;
//返回其他状态码
axios.defaults.validateStatus = function(status) {
  return status >= 200 && status <= 500;
};
// 添加一个请求拦截器
axios.interceptors.request.use(
  (config) => {
    if (!config.url.includes("/loginData")) {
      config.headers["accessToken"] = getToken() || "";
      config.headers["verifyToken"] = getverifyToken() || "";
      config.headers["timestamp"] = new Date().getTime();
      config.headers["sign"] = "0000000000000";
      // config.headers['Content-Type'] = 'application/json'
    }
    return config;
  },
  (err) => {
    return Promise.reject(err);
  }
);
//添加一个响应拦截器
axios.interceptors.response.use(
  (res) => {
    const status = res.status;
    //在这里对返回的数据进行处理
    // 如果请求为非200否者默认统一处理
    if (status === 200) {
      if (res.data.success == true) {
        return res;
      } else if (res.data.success == false) {
        let code = res.data.code.status;
        if (599 >= code && code >= 501) {
          clearCookie();
          location.href = "/login";
        }

        if(res.data.code.message != '不用提醒') ElMessage({message: res.data.code.message, type: "error"});

        return Promise.reject(new Error(res.data.code.message));
        //返回文件
      } else if (res.data.success == undefined) {
        return res;
      }
    } else {
      ElMessage({
        message: "服务器访问失败",
        type: "error"
      });
      return Promise.reject(new Error(res.data.message));
    }
  },
  (err) => {
    return Promise.reject(err);
  }
);

export default axios;
