export default [
    {
        path: "editionupgrade_info",
        name: "editionupgrade_info",
        component: () =>
            import(
                /* webpackChunkName: "about" */ "../views/editionupgrade/info.vue"
                ),
    },
    {
        path: "editionupgrade_app",
        name: "editionupgrade_app",
        component: () =>
            import(
                /* webpackChunkName: "about" */ "../views/editionupgrade/app.vue"
                ),
    },
    {
        path: "editionupgrade_upgrade",
        name: "editionupgrade_upgrade",
        component: () =>
            import(
                /* webpackChunkName: "about" */ "../views/editionupgrade/upgrade.vue"
                ),
    },
];
