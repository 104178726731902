import axios from "@/api/axios"; // 引用axios
import { encrypton } from "@/util/rsa";
//获取用户信息
export const getuserInfo= () => {
  return axios({
    url: "/api/user/getPersonalInfor",
    method: "post",
    params: { }
  });
};


export const editPwd = ( data ) => {
  data.oldPsw = encrypton(data.oldPsw);
  data.newPsw = encrypton(data.newPsw);
  return axios({
    url: "/api/user/editPwd",
    method: "post",
    params: data
  });
};
