export default [
    {
        path: "popularization_house",
        name: "popularization_house",
        component: () =>
            import(
                /* webpackChunkName: "house" */ "../views/popularization/house.vue"
                ),
    },
    {
        path: "popularization_community",
        name: "popularization_community",
        component: () =>
            import(
                /* webpackChunkName: "community" */ "../views/popularization/community.vue"
                ),
    },
    {
        path: "popularization_build",
        name: "popularization_build",
        component: () =>
            import(
                /* webpackChunkName: "build" */ "../views/popularization/build.vue"
                ),
    },
    {
        path: "popularization_hometerminals",
        name: "popularization_hometerminals",
        component: () =>
            import(
                /* webpackChunkName: "hometerminals" */ "../views/popularization/hometerminals.vue"
                ),
    },
    {
        path: "popularization_callRecord",
        name: "popularization_callRecord",
        component: () =>
            import(
                /* webpackChunkName: "callRecord" */ "../views/popularization/callRecord.vue"
                ),
    },
    {
        path: "popularization_financialmedia",
        name: "popularization_financialmedia",
        component: () =>
            import(
                /* webpackChunkName: "callRecord" */ "../views/popularization/financialmedia.vue"
                ),
    },
    {
        path: "popularization_statistics",
        name: "popularization_statistics",
        component: () =>
            import(
                /* webpackChunkName: "callRecord" */ "../views/popularization/statistics.vue"
                ),
    },
    {
        path: "popularization_feedback",
        name: "popularization_feedback",
        component: () =>
            import(
                /* webpackChunkName: "callRecord" */ "../views/popularization/feedback.vue"
                ),
    },
]