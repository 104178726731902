export default [
  {
    path: "terminalRegulate_generalVolume",
    name: "terminalRegulate_generalVolume",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/terminalRegulate/generalVolume.vue")
  },{
    path: "terminalRegulate_encodeSetting",
    name: "terminalRegulate_encodeSetting",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/terminalRegulate/encodeSetting.vue")
  },{
    path: "terminalRegulate_tminalNetSet",
    name: "terminalRegulate_tminalNetSet",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/terminalRegulate/tminalNetSet.vue")
  },{
    path: "terminalRegulate_returnSet",
    name: "terminalRegulate_returnSet",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/terminalRegulate/returnSet.vue")
  },{
    path: "terminalRegulate_passBackcycle",
    name: "terminalRegulate_passBackcycle",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/terminalRegulate/passBackcycle.vue")
  },{
    path: "terminalRegulate_timeCalibrate",
    name: "terminalRegulate_timeCalibrate",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/terminalRegulate/timeCalibrate.vue")
  },{
    path: "terminalRegulate_switchConfig",
    name: "terminalRegulate_switchConfig",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/terminalRegulate/switchConfig.vue")
  },{
    path: "terminalRegulate_lockFrequency",
    name: "terminalRegulate_lockFrequency",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/terminalRegulate/lockFrequency.vue")
  },{
    path: "terminalRegulate_maintainSet",
    name: "terminalRegulate_maintainSet",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/terminalRegulate/maintainSet.vue")
  },{
    path: "terminalRegulate_parameterStatus",
    name: "terminalRegulate_parameterStatus",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/terminalRegulate/parameterStatus.vue")
  },
  {
    path: "terminalRegulate_check",
    name: "terminalRegulate_check",
    component: () =>
        import(
            /* webpackChunkName: "check" */ "../views/terminalRegulate/check.vue"
            ),
  },
  {
    path: "terminalRegulate_scan",
    name: "terminalRegulate_scan",
    component: () =>
        import(
            /* webpackChunkName: "scan" */ "../views/terminalRegulate/scan.vue"
            ),
  },
  {
    path: "terminalRegulate_restoration",
    name: "terminalRegulate_restoration",
    component: () =>
        import(
            /* webpackChunkName: "restoration" */ "../views/terminalRegulate/restoration.vue"
            ),
  },
  {
    path: "terminalRegulate_restoreFactory",
    name: "terminalRegulate_restoreFactory",
    component: () =>
        import(
            /* webpackChunkName: "restoration" */ "../views/terminalRegulate/restoreFactory.vue"
            ),
  },
  {
    path: "terminalRegulate_setInstruction",
    name: "terminalRegulate_setInstruction",
    component: () =>
        import(
            /* webpackChunkName: "restoration" */ "../views/terminalRegulate/setInstruction.vue"
            ),
  },
]