import { createStore } from "vuex";
import user from "./user";
import menu from "./menu";
import getter from "./getter";
export default createStore({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    user,
    menu,
  },
  getters: getter,
});
